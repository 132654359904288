<template>
  <div class="contents">
    <el-card>
      <el-row>
        <el-col>
          <h1>台灣櫻花會員服務專屬權益</h1>

          <p>
            「SAKURA i Care」，不只是單純的售後服務，更是櫻花對消費者溫柔且堅定的宣示：因為對你時刻在乎，我們提供「永久」、「免費」的五大服務，為你的生活創造永恆不變的安心守護！讓美好生活「永保如新」我們以創新的智能化服務，讓你輕鬆享受美好生活。從安裝前的專業評估到個人化的產品保養服務，期望提供給消費者最完整且專業的服務旅程。
          </p>
          <p>
            成為櫻花會員即可享有：<br>
            1. 最新優惠訊息通知<br>
            2. 參與會員專屬活動<br>
            3. 便利預約服務<br>
            4. icare  服務
          </p>

          <p>＊需透過回函登錄櫻花產品並完成櫻花  LINE  官方帳號個人化服務綁定，即可享有完整售後服務，並獲得專屬即時資訊。</p>

          <h2>一般服務條款：</h2>

          <p>1. 您對本服務之使用行為</p>

          <p>1.1 若您為未滿二十歲之未成年人，應與您的家長（或監護人）一同閱讀、瞭解並同意本條款之所有內容及其後修改變更，方得註冊為會員、使用或繼續使用本服務。當您使用或繼續使用本服務時，即推定您的家長（或監護人）已閱讀、瞭解並同意接受本條款之所有內容及其後修改變更。</p>

          <p>1.2 會員應提供您本人正確、最新的資料，且即時維持並更新，確保其正確性，以獲取最佳之服務。若會員有提供任何錯誤或不實資料、或未按指示提供資料、或欠缺必要之資料等情事時，本公司有權不經事先通知，逕行暫停或終止會員的帳號，並拒絕您使用本服務之全部或一部份。</p>

          <p>1.3 當您使用本服務時，可能會因此衍生第三方（例如您的網際網路供應商或行動通訊業者）所收取之存取費用或資料傳輸費用。此類衍生性費用必須由您自行負擔。</p>

          <hr>

          <h2>個人資料權益聲明</h2>
          一、本公司(台灣櫻花股份有限公司)因會員服務業務而蒐集、處理或利用之個人資料時，皆以尊重您的權益為基礎，並以誠實信用之方式及以下原則為之。<br>
          二、本公司蒐集您個人資料之目的係因產品回函卡所有服務、行銷、統計調查與分析或資訊與資料庫管理之執行，凡依規定辦理與推廣活動之行為皆屬之。本公司僅會請您提供為回函卡之執行而必要之個人資料，包含姓名、出生年月日與聯絡方式等個人資料類別。<br>
          三、本公司蒐集您之個人資料只會在服務契約成立前階段、契約存續期間或經您同意之期間被處理或利用，惟相關法令另有保存期限規定者從其之。您的個人資料原則上僅會以電子檔或紙本形式於我國台澎金馬等中華民國所轄境內供本公司與本公司之委外廠商處理及利用。若您需要更近一步了解您的個人資料之處理或利用的詳細情形，歡迎您與我們聯繫。<br>
          四、本公司對於蒐集、處理或利用任何之個人資料皆以遵循本公司之個人資料管理目標與政策為指導原則，並訂有完善之個人資料檔案安全維護計畫，任何流程皆有嚴格之管控程序及標準作業流程，您可以安心的將您的個人資料提供予本公司。<br>
          五、本公司保有您的個人資料時，基於個人資料保護法規定，您可以行使下述的權利：<br>
          (1)查詢或請求閱覽您的個人資料。<br>
          (2)請求製給您的個人資料複製本。<br>
          (3)請求補充或更正您的個人資料。<br>
          (4)請求停止蒐集、處理或利用您的個人資料。<br>
          (5)請求刪除您的個人資料<br>
          ※您欲行使上述提及之相關權利時，本公司的服務聯繫窗口能受理您的需求。<br>
          ※您可以在本公司官方網站(網址：https://www.sakura.com.tw/)上找到聯繫我們的在電話號碼、電子郵件信箱。<br>

          <p>六、本公司基於上述原因需蒐集、處理或利用您的個人資料時，您可以自由選擇是否提供您的個人資料。若您選擇不提供個人資料或是提供不完全時，基於健全回函卡業務之執行，本公司將可能無法提供您完善的服務(如：維修、安檢等服務)。</p>

          <p>七、本公司將於法令規定前提下，不定期更新或變更相關資訊，相關資訊以更新後者為準。</p>
          <!--
          <h2>櫻花點數商城</h2>

          <p>目前櫻花淨水管家開放使用點數商城，多項兌換品項將陸續上架，給予會員更多專屬好康。</p>

          <h3>一、最新活動</h3>
          櫻花將不定期舉辦會員專屬活動，透過每一次的活動來累積點數。

          <h3>二、兌換商城</h3>
          兌換品項需以點數換取，兌換成功時，將由專人送至府上，讓您享有專屬禮遇。

          <h3>三、兌換紀錄</h3>
          您可以隨時查閱自己所兌換過的品項，清楚記錄自己的會員好禮清單。

          <h3>四、常見問題</h3>
          要如何獲得會員點數？<br>
          點數獲得條件將依照每一次的會員活動辦法有所不同，並依台灣櫻花股份有限公司官方網站公告之辦法為累積點數原則。<br>
          點數可以換成現金嗎?<br>
          當前的點數機制僅規劃能夠兌換商城提供之商品或服務。<br>
          點數能夠轉讓嗎?<br>
          所有的點數回饋皆歸戶於同一個消費者名義底下，無法轉讓。<br>
          我的點數有期限嗎?<br>
          當年度累計點數，點數將於隔年底全數歸零。例: 2022/5/1~2022/12/31累積的點數，兌換使用期限為2023/12/31，逾期點數全數歸零。<br>
          點數會因為我的淨水器異動而有影響嗎?<br>
          因為點數是依據消費者個人資料做紀錄，因此您若遇到淨水器轉移給其他親友使用，您所累積的點數將不會遭清除。<br>
          若自己是櫻花的客戶，但沒有購買淨水器，有辦法透過活動累積點數嗎?<br>
          只要是櫻花的客戶，皆可加入櫻花淨水管家LINE帳號好友，完成會員註冊綁定，即可透過揪友賺濾心等活動來累積點數。<br>

          <h3>五、服務條款</h3>
          1. 會員服務<br>
          1.1 台灣櫻花股份有限公司會員服務網站（以下稱本網站）以提供商品訂購為主要服務，其包含客服人員諮詢、多重付款機制、各類行銷資訊提供、顧客相關權益通知（包括但不限於試用品推廣、抽∕贈獎活動、紅利集點活動、服務滿意度調查），並將依業務推展及消費者需求，視情況增加、修改或終止相關服務（以上會員服務，如本網站另有公告，將以實際公告為準）。<br>
          1.2 本網站有權隨時修改或變更本服務條款內容，並將修改後的服務條款內容以電子郵件、電話、通信網路、網站或其他適當方式公告，建議會員隨時注意該等修改或變更。會員若於任何修改或變更後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改或變更。<br>
          2. 本網站保留隨時修改、變更、暫停或終止本活動內容之權利，並以本網站網站公告為準。若有其他未盡事宜，悉依本公司相關規定或解釋。<br>
          3. 本網站提供會員紅利點數回饋方案，凡本網站會員得依本網站回饋辦法累積點數，就累積之紅利點數量選擇兌換商品或服務。<br>
          4. 以兌換方式換得之商品或服務，一經會員兌換後，除因商品本身具有瑕疵外，均不得退貨或更換。如因商品瑕疵需退換貨者，應於收到商品七天內通知本網站客服人員。<br>
          5. 前項申請兌換之商品或服務停止提供時，本公司有權變更商品或服務項目，並另提供其他等值商品或服務代之。<br>
          6. 若商品或服務之成本增加或因市價波動或其它原因，本網站有權經網站公告或其他公開方式變更商品或服務項目及調整兌換。變更或調整後，兌換商品或紅利點數以網站或其他公開方式最新公告之數量、金額為準。<br>
          7. 本網站以提供良好使用環境為目標，對於會員或使用者之特定要求或需求，包括但不限於速度、安全性、可靠性、完整性、正確性及不會斷線或出錯等，本網站不作任何明示或默示之擔保或保證，除因本網站故意或重大過失所致者外，本網站不負任何賠償責任。<br>
          8. 若會員自行選擇帳號停止使用，或因惡意行為遭到本網站判定停權時，該會員帳號內之紅利點數即自動失效；若會員的帳號遭暫停一定期間者，紅利點數在該期間內若已屆期者，即失其效力，會員不得對本網站主張任何權利。<br>
          9. 當年度累計點數，點數將於隔年底全數歸零。例:2022/5/1~2022/12/31累積的點數，兌換使用期限為2023/12/31，逾期點數全數歸零。為了實現您的權益，請於期限內使用完畢！本網站對紅利點數之有效期限及適用範圍保有最終解釋權與內容更改之權利。<br>
          10. 如商品規格於網站上刊登有誤，皆以實體商品資料為準。 -->
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
h1 {
    padding: 15px 0;
    font-size: 18px;
    font-weight: 900;
}

h2 {
    padding: 12px 0;
    font-weight: 900;
    font-size: 16px;
}

h3 {
    padding: 10px 0;
    font-weight: 900;
    font-size: 14px;
}

.contents p {
    padding: 10px 0;
}

.contents {
    line-height: 22px;
}
@import '../assets/styles/mixin.scss';
.contents {
  .el-card {
    border-radius: 1rem;
  }
@media screen and (max-width: 992px) {
  .el-card{
    .el-row{
      .el-col:last-child {
        margin-top: 2rem;
        margin-bottom: 1.5rem;
          span {
            bottom: -2rem;
          }
      }

    }
  }
}
}
</style>
